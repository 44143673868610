import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';

import { Link } from 'gatsby';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-85">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
            , {config.title}
          </h1>
          <div className="subheading mb-5">
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
          I am an Associate Professor at Marymount University in the School of Technology and Innovation. I was previously an Assistant Professor of Computer Science at Westfield State University. I completed my PhD in 2013 from Charles University in Prague. My time in Prague was spent as a Marie Curie Early Stage Researcher in NLP and Machine Learning. I was funded through the CLARA (Common Language Resources and their Applications) EU project.
<br/><br/>
  My undergraduate degree was completed at NCSU in 2003. I completed my masters degree in Computer Science from The George Washington University in Washington DC while working for the Bureau of Labor Statistics in 2006. Before attending Charles University in Prague, I spent a year in Iceland on a Fulbright Fellowship and continued my research at NCSU. My research interests are in HCI, XR Technology, Machine Learning and Natural Language Processing, specifically dealing with under-resourced languages and corpus construction.


          </p>

        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="experience"
      >
        <div className="w-95">
          <h2 className="mb-5">Experience</h2>


          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Associate Professor</h3>
              <div className="subheading mb-3">Marymount University</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2021 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Assistant Professor</h3>
              <div className="subheading mb-3">Marymount University</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2016 - 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Assistant Professor</h3>
              <div className="subheading mb-3">Westfield State University</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2013-2016</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Early Stage Research (Clara Project)</h3>
              <div className="subheading mb-3">European Union</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2010 - 2013</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Graduate Research Assistant</h3>
              <div className="subheading mb-3">North Carolina State University</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2007 - 2009</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Fulbright </h3>
              <div className="subheading mb-3">Reykjavik, Iceland</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2006 - 2007</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">IT Specialist (GS-12)</h3>
              <div className="subheading mb-3">The Bureau of Labor Statistics</div>

            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2003 - 2006</span>
            </div>
          </div>

</div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-95">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Charles University</h3>
              <div className="subheading mb-3">Ph.D.</div>
              <div>Natural Language Processing</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2010 - 2013</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">University of Iceland</h3>
              <div className="subheading mb-3">Fulbright</div>
            <div>NLP Research</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2006 - 2007</span>
            </div>
          </div>

          <br/>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">George Washington University</h3>
              <div className="subheading mb-3">M.S.</div>
            <div>Computer Science</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2003 - 2006</span>
            </div>
          </div>

          <br/>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">North Carolina state University</h3>
              <div className="subheading mb-3">B.S.</div>
              <div>Computer Science</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">1999 - 2003</span>
            </div>
          </div>

        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="publications"
      >
        <div className="w-95">
          <h2 className="mb-5"><a href="#Proceedings">Proceedings</a>    : <a href="#Journals">Journals</a>    : <a href="#Books">Book Chapters</a>  </h2>
          <a name="Proceedings"></a>
          <div className="subheading mb-3"> Proceedings  </div>

<strong><a title="[bib]" href="/bib/fie2023.bib">[bib]</a><a href='https://ieeexplore.ieee.org/document/10343001' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> <em>Creating Tangible VR Spaces for Exploring Algorithm Complexity and Data Structures</em>, 2023 IEEE Frontiers in Education Conference (FIE), College Station, TX, USA, 2023, pp. 1-5
<br/><br/>
          <strong><a title="[bib]" href="/bib/web3d2022.bib">[bib]</a><a href='https://dl.acm.org/doi/10.1145/3564533.3564571' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Karen Works, <em>Defining the Metaverse through the lens of academic scholarship, news articles, and social media</em> In Proceedings of the 27th International Conference on 3D Web Technology (Web3D '22). Association for Computing Machinery, New York, NY, USA, Article 10, 1–5.
      <br/><br/>

          <strong><a title="[bib]" href="/bib/hri2022.bib">[bib]</a> <a href='https://dl.acm.org/doi/abs/10.5555/3523760.3523875' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Karen Works, <em>Measuring Users Attitudinal and Behavioral Responses to Persuasive Communication Techniques in Human Robot Interaction.</em> Proceedings of the 2022 ACM/IEEE International Conference on Human-Robot Interaction, Hokkaido, Japan, 2022
<br/><br/>
<strong><a title="[bib]" href="/bib/sedsi2022factrepo.bib">[bib]</a>  <a href='https://www.sedsi.org/_files/ugd/f590f6_475fc3da9f904956a4e1b289745b6f4a.pdf#page=618' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong>, Septina Larasati, Daniel Duro, Diane Murphy, and Kathryn Laskey , <em>Fact Rep : A Machine Learning Resource for Identifying and analyzing Misinformation During the COVID-19 Pandemic</em> Proceedings of the 51st Annual Meeting of the Southeast Decision Sciences Institute (SEDSI), Jacksonville, Florida, 2022
<br/><br/>
<strong><a title="[bib]" href="/bib/sedsi2022fashion.bib">[bib]</a>  <a href='https://www.sedsi.org/_files/ugd/f590f6_475fc3da9f904956a4e1b289745b6f4a.pdf#page=324' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong>, and Jennifer Yang , <em>Constructing Language Resources for Fashion Merchandising Majors For A Generalized AI Curriculum</em> Proceedings of the 51st Annual Meeting of the Southeast Decision Sciences Institute (SEDSI), Jacksonville, Florida, 2022
<br/><br/>

          <strong><a title="[bib]" href="/bib/edsigncon2019.bib">[bib]</a> <a href='http://proc.iscap.info/2019/pdf/4925.pdf' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong>, Michelle (Xiang) Liu, and Diane Murphy, <em>Developing an Electronic Resume Analyzer Portal (e-RAP): A Natural Language Processing Approach to Enhance College Graduates Job Readiness.</em> Proceedings of the EDSIG Conference on Information Systems and Computing Education, Cleveland , Ohio, 2019
<br/><br/>
          <strong><a title="[bib]" href="/bib/lrec2018.bib">[bib]</a> <a href='https://www.aclweb.org/anthology/L18-1441' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Septina Dian Larasati, <em> The First 100 Days: A Corpus Of Political Agendas on Twitter.</em> Proceedings of the 11th International Conference on Language Resources and Evaluation (LREC'18), Miyazaki , Japan, 2018
<br/><br/>
          <strong><a href="/bib/lrec2014.bib">[bib]</a> <a href="http://www.lrec-conf.org/proceedings/lrec2014/pdf/1143_Paper.pdf" target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Septina Dian Larasati, <em>Votter Corpus: A Corpus of Social Polling Language.</em> Proceedings of the Ninth International Conference on Language Resources and Evaluation (LREC'14), Reykjavik, Iceland, 2014
<br/><br/>
          <strong><a title="[bib]" href="/bib/hytra.bib">[bib]</a> <a title="[pdf]" href="https://aclanthology.org/W13-2805.pdf" target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Zdeněk Žabokrtský, <em>Improvements to Syntax-based Machine Translation using Ensemble Dependency Parsers. </em>Proceedings of the ACL 2013 Second Workshop on Hybrid Approaches to Translation (HyTra), Sofia, Bulgaria, 2013
<br/><br/>
          <strong><a title="[bib]" href="/bib/paclic.bib">[bib]</a> <a title="[pdf]" href="https://aclanthology.org/Y12-1014.pdf" target="_blank">[pdf]</a></strong><strong> Nathan Green</strong>,  Septina Dian Larasati and Zdeněk Žabokrtský, <em>Indonesian Dependency Treebank: Annotation and Parsing. </em>Proceedings of the 26th Pacific Asia Conference on Language, Information and Computation (PACLIC), Bali, Indonesia, 2012
<br/><br/>
          <strong><a title="[bib]" href="/bib/acl2012.bib">[bib]</a> <a href="https://aclanthology.org/W12-3410.pdf" target="_blank">[pdf]</a> Nathan Green</strong>, Loganathan Ramasamy and Zdeněk Žabokrtský, <em>Using an SVM Ensemble System for Improved Tamil Dependency Parsing. </em>ACL Joint Workshop on Statistical Parsing and Semantic Processing of Morphologically Rich Languages, Jeju, Republic of Korea, 2012
<br/><br/>
          <strong><strong><a title="[bib]" href="/bib/hybrid.bib">[bib]</a> <a title="[pdf]" href="https://aclanthology.org/W12-0503.pdf">[pdf]</a></strong> Nathan Green</strong> and Zdeněk Žabokrtský, <em>Hybrid Combination of Constituency and Dependency Trees into an Ensemble Dependency Parser</em>. Innovative hybrid approaches to the processing of textual data, Workshop EACL,  Avignon, France, pp. 19-26, 2012
<br/><br/>
          <strong><a title="[bib]" href="/bib/lrec.bib" target="_blank">[bib]</a> <a title="[pdf]" href="http://scholar.google.com/scholar_url?hl=en&amp;q=http://www.lrec-conf.org/proceedings/lrec2012/workshops/22.LREC%25202012%2520Collaborative%2520Resources%2520Proceedings.pdf%23page%3D27&amp;sa=X&amp;scisig=AAGBfm1Q6dYjynPwtykgk0wkcfryMuuJpg&amp;oi=scholaralrt" target="_blank">[pdf]</a> Nathan Green</strong>, <em>Building Parallel Corpora through Social Network Gaming</em>. Collaborative Resource Development and Delivery, Workshop in The Eighth International Conference on Language Resources and Evaluation (LREC) 2012,  Istanbul, Turkey
<br/><br/>
          <strong><a title="[bib]" href="/bib/W11-2153.bib" target="_blank">[bib]</a> <a title="[pdf]" href="https://aclanthology.org/W11-2153.pdf">[pdf]</a></strong> Martin Popel, David Marecek, <strong>Nathan Green</strong> and Zdeněk Žabokrtský,<em> Influence of Parser Choice on Dependency-Based MT</em>, Proceedings of the Sixth Workshop on Statistical Machine Translation, Edinburgh, Scotland, 2011, pp. 433-439, 2011
<br/><br/>
          <strong><a title="[bib]" href="/bib/P11-3013.bib" target="_blank">[bib]</a> <a href="https://aclanthology.org/P11-3013.pdf">[pdf] </a> Nathan Green,</strong> <em>Effects of Noun Phrase Bracketing in Dependency Parsing and Machine Translation</em>, Association for Computational Linguistics (ACL) Student Session, Portland, Oregon, 2011
<br/><br/>
          <strong><a title="[bib]" href="/bib/wds.bib" target="_blank">[bib]</a> <a href="https://www.mff.cuni.cz/veda/konference/wds/proc/pdf11/WDS11_123_i3_Green.pdf">[pdf]</a> </strong><strong>Nathan Green,</strong> <em>Dependency Parsing,</em> WDS'11 Proceedings of Contributed Papers,  Prague Czech Republic, pp. 137-142, 2011
<br/><br/>
          <strong><a title="[bib]" href="/bib/W10-1837.bib" target="_blank">[bib]</a> <a href="https://aclanthology.org/W10-1837.pdf">[pdf]</a></strong> <strong>N. Green</strong>, P. Breimyer, V. Kumar, and N.F. Samatova, <em>PackPlay: Mining semantic data in collaborative games</em>, Association for Computational Linguistics (ACL) in the Proceedings of the Fourth Linguistic Annotation Workshop (LAWIV), Uppsala, Sweden, pp. 227-234, 2010
<br/><br/>
          <strong><a href="/bib/webbanc.bib" target="_blank">[bib]</a> <a href="http://beta.visl.sdu.dk/~eckhard/nodalida/paper_2.pdf">[pdf]</a></strong> <strong>N. Green</strong>, P. Breimyer, V. Kumar, and N.F. Samatova, <em>WebBANC: Building Semantically Rich Annotated Corpora from Web User Annotations of Minority Languages</em>, 17th Nordic Conference on Computational Linguistics, Odense, Denmark, Vol. 4, pp. 48-56, 2009
<br/><br/>
          <strong><a href="/bib/flairs.bib" target="_blank">[bib]</a> <a href="https://cdn.aaai.org/ocs/45/45-2332-1-PB.pdf">[pdf]</a></strong> P. Brent, <strong>N. Green</strong>, P. Breimyer, R. Krishnamurthy, and N.F. Samatova, <em>Systematic Evaluation of Convergence Criteria in Iterative Training for NLP</em>, 22nd International Florida Artificial Intelligence Research Society, Sanibel Island, Florida, 2009
<br/><br/>
          <strong><a href="/bib/biodeal2.bib" target="_blank">[bib]</a> <a href="https://ieeexplore.ieee.org/document/4686215">[pdf]</a></strong> <strong>N. Green</strong>, P. Breimyer, V. Kumar, and N.F. Samatova,<em> BioDEAL: Biological data evidence annotation linkage system</em>, IEEE International Conference on Bioinformatics &amp; Biomedicine Workshops (BIBMW), Philadelphia, PA, pp. 99-106, 2008
<br/><br/>
          <strong><a href="/bib/qwerty.bib" target="_blank">[bib]</a> <a href="http://dl.acm.org/ft_gateway.cfm?id=986082&amp;type=pdf&amp;CFID=89757395&amp;CFTOKEN=83436449">[pdf]</a></strong> <strong>N. Green</strong>, J. Kruger, C. Faldu, and R.S. Amant, <em>A reduced QWERTY keyboard for mobile text entry</em>, ACM's Computer Human Interaction (CHI) Conference on Human Factors in Computing Systems, Vienna, Austria: pp. 1429-1432, 2004
<br/><br/>
          <strong><a href="/bib/fie.bib" target="_blank">[bib]</a> <a href="https://ieeexplore.ieee.org/abstract/document/1408668">[pdf]</a> </strong>M. Heil, R. Fornaro, <strong>N. Green</strong>, J. Maness, and W. Webb, <em>On becoming a winning student team: placing third in an international design competition</em>, 34th Annual Frontiers in Education (FIE), Savannah, GA, pp. F4G-1-5 Vol. 2., 2004


          <div className="subheading mb-3">Journals</div>
          <a name="Journals"></a>
          <strong><a title="[bib]" href="/bib/cyberdata.bib">[bib]</a>  <a href='https://dl.acm.org/doi/10.5555/3636988.3637015' target="_blank">[pdf]</a></strong> Xiang Liu, Diane Murphy,<strong> Nathan Green</strong> <em> The Hunt for Cybersecurity Data: Exploring the Availability of Open Datasets for Cybersecurity Scientific Research</em>, Journal of Computing Sciences in Colleges,39, 3 (October 2023), 171–181.

          <br/><br/>

          <strong><a title="[bib]" href="/bib/ccscse2023.bib">[bib]</a>  <a href='https://dl.acm.org/doi/10.5555/3637068.3637069' target="_blank">[pdf]</a> </strong><strong> Nathan Green</strong> and Karen Works, <em>Sentiment and Topic Modeling Analysis of Reddit Posts on Changing Ones Major</em>, Journal of Computing Sciences in Colleges, 39, 5 (November 2023), 13–22.
        <br/><br/>

          <strong><a title="[bib]" href="/bib/acm_research_trends.bib">[bib]</a> <a href='https://dl.acm.org/doi/abs/10.5555/3580523.3580528' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Karen Works, <em>Are research trends in the Consortium for Computing Sciences in Colleges Regionalized?</em> Journal of Computing Sciences in Colleges, Volume 38, Issue 3, November 2022, pp 40–50
      <br/><br/>

      <strong><a title="[bib]" href="/bib/acm_ccscse.bib">[bib]</a> <a href='https://dl.acm.org/doi/10.5555/3581625.3581627' target="_blank">[pdf]</a></strong><strong> Nathan Green</strong> and Karen Works, <em>Commonalities of Users Influenced and Not Influenced by Persuasive Communication in Human Robot Interactions</em> Journal of Computing Sciences in Colleges, Volume 38, Issue 5, November 2022, pp 21–30
      <br/><br/>

          <strong><a href="/bib/ISEDJ.bib" target="_blank">[bib]</a>  <a href='https://eric.ed.gov/?id=EJ1258209'>[pdf]</a></strong><strong> Nathan Green</strong>, Xiang Liu, Diane Murphy, <em> Using an Electronic Resume Analyzer Portal (eRAP) to Improve College Graduates Employability</em> Information Systems Education Journal, 2020

          <br/><br/>


          <strong><a href="/bib/clute.bib" target="_blank">[bib]</a>  <a href='https://clutejournals.com/index.php/JABR/article/view/10348'>[pdf]</a></strong>Alshameri, F. and <strong>Green , N.</strong>, <em> Analyzing the Strength Between Mission and Vision Statements and Industry Via Machine Learning.</em> Journal of Applied Business Research, 2020 , 36(3), 121-128

          <br/><br/>

          <strong><a href="/bib/ccsc.bib" target="_blank">[bib]</a>  <a href='https://dl.acm.org/citation.cfm?id=3306480'>[pdf]</a></strong><strong> Nathan Green</strong>, Xiang Liu, Diane Murphy, <em> Revisiting an Educator’s Dilemma: Using Natural Language Processing to Analyze the Needs of Employers and Inform Curriculum Development.</em> Journal of Computing Sciences in Colleges, 2019

          <br/><br/>

          <strong><a href="/bib/biodeal.bib" target="_blank">[bib]</a> <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC2773920/">[pdf]</a></strong> <strong>N. Green</strong>, P. Breimyer, V. Kumar, and N.F. Samatova,<em> BioDEAL: Community Generation of Biological Annotations</em>, BMC Medical Informatics and Decision Making, Vol.9 Suppl 1., 2009 (Invited)
    <a name="Books"></a>
        <div className="subheading mb-3">Book Chapters</div>


        <em>Hybrid Approaches to Machine Translation </em>,
        Chapter: Creating Hybrid Dependency Parsers for Syntax-Based MT,  Published July 2016 by Springer


        <br/><br/>

        <em><a title="CRC Press" href="http://www.crcpress.com/product/isbn/9781439860847" target="_blank">Practical Graph Mining with R</a></em>,<br/>
        Chapter 4: Link Analysis<br/>
        Chapter 5: Graph-Based Proximity Measures<br/>
        Published July 15, 2013 by Chapman and Hall/CRC




    </div>
      </section>



      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="software"
      >
        <div className="w-95">
          <h2 className="mb-5">Data & Software</h2>

          <div className="subheading mb-3">Data</div>
          <ul>
          <li><Link to="/wmt/">WMT</Link></li>
          <li>100 Days Twitter Corpus (Available Upon Request)</li>
          </ul>

          <div className="subheading mb-3">Software</div>
          <ul>
            <li><a href='https://www.resumehop.com/' target="_blank">ResumeHop</a></li>
          </ul>


        </div>
      </section>


      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="contact"
      >
        <div className="w-95">
          <h2 className="mb-5">Contact</h2>

          <div className="subheading mb-5">
            <a href={`mailto:${config.email}`}>{config.email}</a>
            <br/>
            or
            <br/>
              <a href={`mailto:${config.email2}`}>{config.email2}</a>
          </div>


          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url,name,text } = social;
              return (

                <a key={url} href={url}>
                  <i className={`fab ${icon}`}>{text}</i> <br/>
                </a>



              );
            })}
          </div>

        </div>
      </section>

    </div>
  </Layout>
);

export default IndexPage;
